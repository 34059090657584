import {PRIVATE_PERSON} from '../../../constants/legalFormTypes'
import {VEHICLE_IDENTIFIER_ECV} from '../../../constants/vehicleIdentifiers'
import {VEHICLE_TYPE_CAR_M1} from '../../../constants/vehicleTypes'


export const initialForm = {
  vehicleIdentifier: VEHICLE_IDENTIFIER_ECV,
  vehicleType: VEHICLE_TYPE_CAR_M1,
  legalForm: PRIVATE_PERSON,
  zipCodeValue: undefined,
  accidents: '0',
  damageClaim: undefined,
  ztp: false,
} as const
