import type {ReactNode, ElementType, HTMLAttributes} from 'react'
import {forwardRef, Children, isValidElement} from 'react'
import type {StyledComponent} from 'styled-components'
import styled, {css} from 'styled-components'
import theme from '../../theme/theme'
import media from '../../utils/media'


export const mainAxisKeys = ['start', 'center', 'end', 'justify', 'evenly', 'unset'] as const
export const crossAxisKeys = ['start', 'center', 'end', 'stretch', 'unset'] as const

type DivProps = HTMLAttributes<HTMLDivElement>

type FlexProps = {
  component?: ElementType
  column?: boolean
  reverse?: boolean
  mainAxis?: (typeof mainAxisKeys)[number]
  crossAxis?: (typeof crossAxisKeys)[number]
  width?: 'fill' | 'hug' | string
  height?: 'fill' | 'hug' | string
  gap?: boolean
  gapSize?: string
  responsive?: boolean
  wrap?: boolean
  distribute?: boolean
  children?: ReactNode
}

type StyledDivProps = {
  $column: FlexProps['column']
  $width: FlexProps['width']
  $height: FlexProps['height']
  $reverse: FlexProps['reverse']
  $crossAxis: FlexProps['crossAxis']
  $mainAxis: FlexProps['mainAxis']
  $gap: FlexProps['gap']
  $gapSize: FlexProps['gapSize']
  $wrap: FlexProps['wrap']
  $responsive: FlexProps['responsive']
  $distribute: FlexProps['distribute']
}

const alignmentMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  justify: 'space-between',
  evenly: 'space-evenly',
  unset: 'unset',
}


const StyledDiv = styled.div<StyledDivProps>`
  /* Disable selector due to incorrect errors */
  /* stylelint-disable indentation, selector-max-empty-lines */
  ${({$column, $width, $height}) => $column
    ? css`
      display: flex;
      width: ${$width === 'fill' ? '100%' : $width === 'hug' ? 'auto' : $width};
      height: ${$height === 'fill' ? '100%' : $height === 'hug' ? 'auto' : $height};
    ` : css`
      display: ${$width === 'fill' ? 'flex' : 'inline-flex'};
      width: ${$width === 'fill' ? 'auto' : $width === 'hug' ? 'auto' : $width};
      height: ${$height === 'fill' ? '100%' : $height === 'hug' ? 'auto' : $height};
    `}

  flex-direction: ${({$column, $reverse}) => `${$column ? 'column' : 'row'}${$reverse ? '-reverse' : ''}`};
  ${({$crossAxis}) => $crossAxis && css`
    align-items: ${alignmentMap[$crossAxis]};
  `}
  ${({$mainAxis}) => $mainAxis && css`
    justify-content: ${alignmentMap[$mainAxis]};
  `}
  ${({$column, $gap, $gapSize}) => {
    if (($gap && $column)) {
      return css`
        margin-top: calc(-${$gapSize} * 0.5);
        margin-bottom: calc(-${$gapSize} * 0.5);
      `
    }
    return css`
      margin-right: calc(-${$gapSize} * 0.5);
      margin-left: calc(-${$gapSize} * 0.5);
    `
  }}

  ${({$wrap}) => $wrap && css`
    flex-wrap: wrap;
  `}

  ${({$responsive, $column, $reverse, $gapSize}) => $responsive && media.down('mobile')(css`
    width: 100%;

    margin-top: calc(-${$gapSize} * 0.5);
    margin-right: 0;
    margin-bottom: calc(-${$gapSize} * 0.5);
    margin-left: 0;

    ${!$column && css<StyledDivProps>`
      display: flex;
      flex-direction: column${$reverse ? '-reverse' : ''};
      ${({$mainAxis}) => $mainAxis && css<StyledDivProps>`
        align-items: ${alignmentMap[$mainAxis]};
      `}
      ${({$crossAxis}) => $crossAxis && css`
        justify-content: ${alignmentMap[$crossAxis]};
      `}
    `}
  `)}

  & > div {
    ${({$distribute}) => $distribute && css`
      flex-basis: 100%;
      flex-shrink: 1;
    `}

    ${({$responsive, $distribute}) => $responsive && $distribute && media.down('mobile')(css`
      flex-shrink: 0;
    `)}

    ${({$column, $gap, $gapSize}) => {
    /* eslint-disable indent */
      if (($column && $gap)) {
        return css`
          padding-top: calc(${$gapSize} * 0.5);
          padding-bottom: calc(${$gapSize} * 0.5);
        `
      }

      return css`
        padding-right: calc(${$gapSize} * 0.5);
        padding-left: calc(${$gapSize} * 0.5);

        ${$gap && media.down('mobile')(css`
          padding-top: calc(${$gapSize} * 0.5);
          padding-bottom: calc(${$gapSize} * 0.5);
        `)}
      `
    }}
  }
`
const Flex = forwardRef<StyledComponent<'div', FlexProps>, DivProps & FlexProps>(({
  component, column, reverse, mainAxis = 'start', crossAxis = 'stretch', width = 'fill',
  height, gap = true, gapSize = theme.spacer, responsive, wrap, distribute, children, ...props
}, ref) => {
    return (
      <StyledDiv
          ref={ref}
          as={component}
          $column={column}
          $reverse={reverse}
          $mainAxis={mainAxis}
          $crossAxis={crossAxis}
          $width={width}
          $height={height}
          $gap={gap}
          $gapSize={gapSize}
          $responsive={responsive}
          $wrap={wrap}
          $distribute={distribute}
          {...props}
      >
        {Children.map(children, (child) => {
      if (!isValidElement(child)) return null
      return (
        <div>
          {child}
        </div>
      )
    })}
      </StyledDiv>
    )
  }
)

Flex.displayName = 'Flex'

export default Flex
