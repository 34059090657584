import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {BooleanInput as VisualBooleanInput} from 'uikit'
import type {FieldTypes} from 'uikit/src/constants/commonPropTypes'
import {showErrorOnBlur} from '../../utils/forms'
import type {ShowError} from '../../utils/forms'


type VisualBooleanInputProps = ComponentProps<typeof VisualBooleanInput>

type BooleanInputProps = FieldTypes & {
  fieldProps?: UseFieldConfig<string, boolean>
  showError?: ShowError
}

const BooleanInput: FC<VisualBooleanInputProps & BooleanInputProps> = ({
  name, label, required, helperText, showError = showErrorOnBlur, fieldProps, ...props
}) => {
  const {input: {value, onChange, checked, ...restInput}, meta} = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})
  const {touched} = meta
  const state = isError ? 'invalid' : touched ? 'valid' : undefined

  return (
    <VisualBooleanInput
        value={value}
        state={state}
        label={label}
        checked={checked}
        helperText={helperTextOrError}
        {...restInput}
        required={required}
        onChange={onChange}
        {...props}
    />
  )
}

export default BooleanInput
