import {isBoolean} from 'lodash'
import type {ChangeEvent} from 'react'
import {useState} from 'react'


type ChangeEventHandler = (event?: boolean | ChangeEvent<HTMLInputElement>) => void
type ControlledValue = string | boolean | ChangeEvent<HTMLInputElement>

const useControlledValue = (value?: ControlledValue, onChange?: ChangeEventHandler) => {
  const [controlledValue, setControlledValue] = useState(value)

  const controlledOnChange: ChangeEventHandler = (event) => {
    if (isBoolean(event)) {
      setControlledValue(event)
    } else if (event && event.target) {
      setControlledValue(event.target.value)
    }
    if (onChange) onChange(event)
  }

  return {controlledValue, controlledOnChange}
}

export default useControlledValue
