import type {ComponentProps, FC} from 'react'
import useMediaTier from '../../../hooks/useMediaTier'
import {lazyAfterLoad} from '../../../utils/lazyAfterLoad'
import Skeleton from '../../Skeleton'
import SSRSuspense from '../../overlays/SSRSuspense'


const SelectLazy = lazyAfterLoad(() => import(/* webpackChunkName: "select" */ './Select'))


const Select: FC<ComponentProps<typeof SelectLazy>> = ({variant = 'default', color = 'text', ...props}) => {
  const {mediaMatches: isMobile} = useMediaTier('mobile')

  return (
    <SSRSuspense
        fallback={
          <Skeleton
              height={variant === 'narrow' ? isMobile ? '22px' : '28px' : '44px'}
              color={color}
          />
        }
    >
      <SelectLazy variant={variant} color={color} {...props} />
    </SSRSuspense>
  )
}

export default Select
