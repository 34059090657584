export const FUEL_BENZIN = 'benzin' as const
export const FUEL_NAFTA = 'nafta' as const
export const FUEL_BENZIN_ELEKTRO = 'benzin_elektro' as const
export const FUEL_BENZIN_ELEKTRO_PLUG_IN = 'benzin_elektro_plug_in' as const
export const FUEL_NAFTA_ELEKTRO = 'nafta_elektro' as const
export const FUEL_NAFTA_ELEKTRO_PLUG_IN = 'nafta_elektro_plug_in' as const
export const FUEL_ELEKTRO = 'elektro' as const
export const FUEL_BENZIN_GAS = 'benzin_gas' as const
export const FUEL_GAS = 'gas' as const
export const ALL_FUELS = [
  FUEL_BENZIN,
  FUEL_NAFTA,
  FUEL_BENZIN_ELEKTRO,
  FUEL_BENZIN_ELEKTRO_PLUG_IN,
  FUEL_NAFTA_ELEKTRO,
  FUEL_NAFTA_ELEKTRO_PLUG_IN,
  FUEL_ELEKTRO,
] as const

export type Fuel = typeof ALL_FUELS[number]

export const ALL_EXTENDED_FUELS = [
  ...ALL_FUELS,
  FUEL_BENZIN_GAS,
  FUEL_GAS,
] as const
