import {Choose, If, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import {isEmpty, keys} from 'lodash-es'
import {darken} from 'polished'
import type {ChangeEvent, FC, InputHTMLAttributes, ReactNode} from 'react'
import {useState} from 'react'
import styled, {css} from 'styled-components'
import type {FieldTypes} from '../../constants/commonPropTypes'
import useControlledValue from '../../hooks/useControlledValue'
import theme from '../../theme/theme'
import * as typography from '../../theme/typography'
import ArrowDownIcon from '../icons/components/ArrowDownIcon'
import ArrowUpIcon from '../icons/components/ArrowUpIcon'
import HelperText from '../typography/HelperText'


type InputElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>

type BooleanInputProps = Omit<FieldTypes, 'value'> & {
  checked?: string | boolean | ChangeEvent<HTMLInputElement>
  value?: string | boolean | ChangeEvent<HTMLInputElement>
  options?: string[]
  showMoreText?: ReactNode
  showLessText?: ReactNode
}

type StyledLabelProps = {
  $state: BooleanInputProps['state']
}

type StyledOptionContainerProps = {
  $checked: BooleanInputProps['checked']
  $checkedOnFalse?: boolean
  $disabled: BooleanInputProps['disabled']
}

type StyledCircleProps = {
  $checked: BooleanInputProps['checked']
  $checkedOnFalse?: boolean
}

type StyledOptionProps = {
  $state: BooleanInputProps['state']
}

const stateVariants = {
  valid: css`
    color: ${({theme}) => theme.colors.text};
  `,
  invalid: css`
    color: ${({theme}) => theme.colors.negative2};
  `,
}

const StyledBooleanInputContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`

const StyledLabel = styled.span<StyledLabelProps>`
  padding-right: ${({theme}) => theme.spacer};

  ${({$state}) => $state && stateVariants[$state]}
`

const StyledAsterisk = styled.sup`
  color: ${({theme}) => theme.colors.primary};
`

const StyledShowDetail = styled.span`
  display: inline-flex;
  margin-left: 0.5rem;
  cursor: pointer;
  color: ${({theme}) => theme.colors.primary};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
`

const ArrowIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  padding-left: 0.5rem;
`

const StyledContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const StyledCircleContainer = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  width: 1.5rem;
`

const StyledCircle = styled.span<StyledCircleProps>`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `border ${theme.animation}`};
  border: ${({theme}) => `${theme.border.default} ${theme.colors.auxiliary3}`};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.text};
  width: 1.25rem;
  height: 1.25rem;

  ${({$checked, $checkedOnFalse}) => $checked && !$checkedOnFalse && css`
    border: 5px solid ${({theme}) => theme.colors.positive3};
  `}

  ${({$checked, $checkedOnFalse}) => $checked === false && $checkedOnFalse && css`
    border: 5px solid ${({theme}) => theme.colors.positive3};
  `}
`

const StyledOptionContainer = styled.div<StyledOptionContainerProps>`
  display: flex;

  ${({$disabled}) => $disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}

  &:hover,
  &:focus {
    ${StyledCircle} {
      ${({$checked, $checkedOnFalse}) => !$checked && !$checkedOnFalse && css`
        border: 5px solid ${({theme}) => darken(0.2, theme.colors.auxiliary3)};
      `}
      ${({$checked, $checkedOnFalse}) => $checked !== false && $checkedOnFalse && css`
        border: 5px solid ${({theme}) => darken(0.2, theme.colors.auxiliary3)};
      `}
    }
  }
`

const StyledOption = styled.button<StyledOptionProps>`
  ${typography.input}

  border: none;
  background: transparent;
  cursor: pointer;
  padding-inline: 0 ${({theme}) => theme.spacer};
  text-transform: uppercase;
  color: inherit;
  font-weight: ${({theme}) => theme.fonts.weight.bold};

  ${({$state}) => $state && stateVariants[$state]}
`

const StyledInput = styled.input`
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
`

const StyledHelperText = styled(HelperText)`
  display: flex;
  justify-content: right;
  padding-right: ${({theme}) => theme.spacer};
  color: ${({theme}) => theme.colors.text};

  ${({state}) => state && stateVariants[state]}
`

const BooleanInput: FC<InputElementProps & BooleanInputProps> = ({
  name, value, state, label, options, onChange, onBlur, onFocus, disabled, required, helperText, showMoreText,
  showLessText, children, ...props
}) => {
  const {controlledValue, controlledOnChange} = useControlledValue(value, onChange)
  const [openDetail, setOpenDetail] = useState(false)

  const toggleDetail = () => {
    setOpenDetail((prevState) => !prevState)
  }

  const handleChange = () => {
    if (onFocus) onFocus()
    if (controlledValue === undefined || controlledValue === '' || controlledValue === null) {
      if (onBlur) onBlur(false)
      return controlledOnChange(false)
    }
    if (onBlur) onBlur(!controlledValue)
    return controlledOnChange(!controlledValue)
  }

  const setChecked = (checked: boolean) => {
    if (!disabled) {
      if (onFocus) onFocus()
      controlledOnChange(checked)
      if (onBlur) onBlur(checked)
    }
  }

  return (
    <>
      <StyledBooleanInputContainer>
        <div>
          <StyledLabel $state={state}>
            {label}
          </StyledLabel>
          <If condition={required}>
            <StyledAsterisk>
              *
            </StyledAsterisk>
          </If>
          <If condition={children}>
            <StyledShowDetail onClick={toggleDetail}>
              <Choose>
                <When condition={!openDetail}>
                  {showMoreText}
                  <ArrowIconWrapper>
                    <ArrowDownIcon fill={theme.colors.primary} size={18} />
                  </ArrowIconWrapper>
                </When>
                <Otherwise>
                  {showLessText}
                  <ArrowIconWrapper>
                    <ArrowUpIcon fill={theme.colors.primary} size={18} />
                  </ArrowIconWrapper>
                </Otherwise>
              </Choose>
            </StyledShowDetail>
          </If>
        </div>
        <StyledContainer>
          <StyledOptionContainer $checked={controlledValue} $disabled={disabled}>
            <StyledCircleContainer onClick={() => setChecked(true)}>
              <StyledCircle $checked={controlledValue} />
            </StyledCircleContainer>
            <StyledOption
                type="button"
                tabIndex={-1}
                $state={state}
                onClick={() => setChecked(true)}
                onBlur={onBlur}
                onFocus={onFocus}
            >
              {options ? options[0] : null}
            </StyledOption>
          </StyledOptionContainer>
          <StyledInput
              type="checkbox"
              id={name}
              checked={controlledValue as boolean}
              name={name}
              value={controlledValue as string}
              onChange={handleChange}
              disabled={disabled}
              required={required}
              {...props}
          />
          <StyledOptionContainer $checked={controlledValue} $checkedOnFalse $disabled={disabled}>
            <StyledCircleContainer onClick={() => setChecked(false)}>
              <StyledCircle $checkedOnFalse $checked={controlledValue} />
            </StyledCircleContainer>
            <StyledOption
                type="button"
                tabIndex={-1}
                $state={state}
                onClick={() => setChecked(false)}
                onBlur={onBlur}
                onFocus={onFocus}
            >
              {options ? options[1] : null}
            </StyledOption>
          </StyledOptionContainer>
        </StyledContainer>
      </StyledBooleanInputContainer>
      <If condition={!isEmpty(helperText)}>
        <StyledHelperText state={state}>{helperText}</StyledHelperText>
      </If>
      <If condition={openDetail}>
        {children}
      </If>
    </>
  )
}

export const stateVariantKeys = keys(stateVariants)

export default BooleanInput
