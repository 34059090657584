export const accidentsCompany = [
  {value: 0, label: 'Žiadna škodová udalosť'},
  {value: 1, label: '1 škodová udalosť'},
  {value: 2, label: '2 škodové udalosti'},
  {value: 3, label: '3 škodové udalosti'},
  {value: 4, label: '4 škodové udalosti'},
]

export const accidentsPrivate = [
  ...accidentsCompany,
  {value: 5, label: '5 škodových udalostí'},
  {value: 6, label: '6 škodových udalostí'},
  {value: 7, label: '7 škodových udalostí'},
  {value: 8, label: '8 škodových udalostí'},
  {value: 9, label: '9 škodových udalostí'},
  {value: 10, label: '10 škodových udalostí'},
  {value: 11, label: '11 škodových udalostí'},
]
