import {keys} from 'lodash-es'
import {transparentize} from 'polished'
import type {ButtonHTMLAttributes, ComponentProps, ElementType, FC, ReactNode} from 'react'
import styled, {css} from 'styled-components'
import type {Color} from '../theme/theme'
import theme, {px2rem} from '../theme/theme'
import media from '../utils/media'
import {InfoIcon} from './icons'


type InfoIconProps = ComponentProps<typeof InfoIcon>

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

type HoverableTooltipProps = {
  component?: ElementType
  variant?: keyof typeof applyVariant
  iconColor?: Color
  iconSize?: InfoIconProps['size']
  Icon?: ElementType
  children: ReactNode
}

type StyledTooltipProps = {
  $variant: HoverableTooltipProps['variant']
}

type TooltipWrapperProps = {
  $variant: HoverableTooltipProps['variant']
}

const applyVariant = {
  default: {
    wrapper: css`
      ${media.down('mobile')(css`
        position: unset;
        padding: 0.125rem;
      `)}
    `,
    tooltip: css`
      top: 50%;
      right: calc(100% + 0.75rem);
      transform: translateY(-50%);
      background-color: ${({theme}) => theme.colors.text};
      max-width: ${px2rem(227)};
      color: ${({theme}) => theme.colors.textInverted};
      fill: ${({theme}) => theme.colors.background7};

      ${media.down('mobile')(css`
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `)}
    `,
  },
  secondary: {
    wrapper: css`
      margin-right: calc(1rem + 1px);
      padding: 0;
    `,
    tooltip: css`
      top: 50%;
      right: calc(100% + 0.75rem);
      transform: translateY(-50%);
      border: ${({theme}) => `1px solid ${transparentize(0.8, theme.colors.text)}`};
      background-color: ${({theme}) => theme.colors.background10};
      max-width: ${px2rem(280)};
      color: ${({theme}) => theme.colors.text};
      fill: ${({theme}) => theme.colors.text};
    `,
  },
  top: {
    wrapper: css`
      padding: 0;
    `,
    tooltip: css`
      bottom: calc(100% + 1rem);
      left: 50%;
      border: ${({theme}) => `1px solid ${transparentize(0.8, theme.colors.textInverted)}`};
      transform: translateX(-50%);
      background-color: ${({theme}) => theme.colors.text};
      max-width: ${px2rem(256)};
      color: ${({theme}) => theme.colors.textInverted};
      fill: ${({theme}) => theme.colors.background7};

      path,
      g {
        fill: ${({theme}) => theme.colors.background7} !important;
      }

      ${media.down('mobile')(css`
        max-width: ${px2rem(228)};
      `)}
    `,
  },
}

const StyledTooltip = styled.div<StyledTooltipProps>`
  display: none;
  position: absolute;
  transition: opacity 0.5s;
  opacity: 0;
  z-index: 10;
  border-radius: ${({theme}) => theme.border.radius.sm};
  box-shadow: ${({theme}) => theme.shadow.paper};
  padding: ${px2rem(24)};
  width: max-content;
  text-align: left;

  ${({$variant}) => $variant && applyVariant[$variant].tooltip}
`

const TooltipWrapper = styled.button<TooltipWrapperProps>`
  display: flex;
  position: relative;
  border: none;
  background-color: transparent;
  cursor: help;
  pointer-events: fill;

  ${({$variant}) => $variant && applyVariant[$variant].wrapper}

  &:hover,
  &:focus {
    ${StyledTooltip} {
      display: block;
      transition: opacity 0.5s;
      opacity: 1;
    }
  }
`

const HoverableTooltip: FC<ButtonProps & HoverableTooltipProps> = ({
  component, variant = 'default', iconColor = 'background7', children, iconSize = 'md', Icon = InfoIcon, ...props
}) => (
  <TooltipWrapper as={component} $variant={variant} {...props}>
    <Icon fill={theme.colors[iconColor]} size={iconSize} />
    <StyledTooltip $variant={variant}>
      {children}
    </StyledTooltip>
  </TooltipWrapper>
)

export const variantKeys = keys(applyVariant)

export default HoverableTooltip
