import type {ComponentProps, FC} from 'react'
import {ReactComponent as Info} from '../../../assets/icons/info.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const InfoIcon: FC<IconProps> = (props) => (
  <Icon icon={Info} {...props} />
)

export default InfoIcon
