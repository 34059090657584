import type {ComponentProps, FC} from 'react'
import {ReactComponent as CircleCheckMark} from '../../../assets/icons/circleCheckMark.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const CircleCheckMarkIcon: FC<IconProps> = (props) => (
  <Icon icon={CircleCheckMark} {...props} />
)

export default CircleCheckMarkIcon
