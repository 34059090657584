import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {FormComponent, Select} from 'uikit'
import type {FieldTypes} from 'uikit/src/constants/commonPropTypes'
import type {ShowError} from '../../utils/forms'
import {showErrorOnBlur} from '../../utils/forms'


type SelectProps = ComponentProps<typeof Select>

type AutocompleteInputProps = FieldTypes & {
  fieldProps?: UseFieldConfig<string, string>
  showError?: ShowError
}

const AutocompleteInput: FC<Partial<SelectProps> & AutocompleteInputProps> = ({
  name, label, onChange, required, helperText, searchLength, disabled, showError = showErrorOnBlur, fieldProps, ...props
}) => {
  const {input: {value, onChange: onFieldChange, ...restInput}, meta} = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})
  const {touched} = meta
  const state = isError ? 'invalid' : touched && value ? 'valid' : undefined

  const handleChange: SelectProps['onChange'] = (e) => {
    if (onChange) onChange(e)
    onFieldChange(e)
  }

  return (
    <FormComponent
        value={value}
        label={label}
        state={state}
        helperText={helperTextOrError || undefined}
        required={required}
        disabled={disabled}
        {...restInput}
    >
      <Select
          searchLength={searchLength}
          autocomplete
          value={value}
          label={label}
          disabled={disabled}
          state={state}
          {...restInput}
          required={required}
          onChange={handleChange}
          {...props}
      />
    </FormComponent>
  )
}

export default AutocompleteInput
