import type {Vehicle as VehicleResponse} from 'backend/src/responses'
import {useCallback} from 'react'
import type {ExtendedCalculation} from '../../../common/schemas/calculations'
import type {VehicleIdentifier} from '../../../constants/vehicleIdentifiers'
import type {GeneralForm} from '../components/screens/form/utils'
import {initialForm} from '../constants/form'
import useStoredState from './useStoredState'


const FORM = 'form'
const IS_EDITING = 'isEditing'

export type CustomFields = {
  vehicleIdentifier: VehicleIdentifier,
  zipCodeValue?: string,
  accidents: string,
  damageClaim: GeneralForm['damageClaim'],
}

type InitialStoredForm = {
  vehicleType: ExtendedCalculation['vehicleType'],
  legalForm: ExtendedCalculation['legalForm'],
} & CustomFields

type Vehicle = Omit<Partial<VehicleResponse>, 'vehicleType' | 'fuel'>

export type StoredForm = InitialStoredForm
  & Partial<GeneralForm>
  & Vehicle


const useStoredForm = () => {
  const [storedForm, setStoredForm] = useStoredState<StoredForm>(initialForm, FORM)
  const [isEditing, setEditing] = useStoredState<boolean>(false, IS_EDITING)

  const saveForm = (values: Partial<StoredForm>) => {
    setStoredForm((prevState) => ({...prevState, ...values}))
  }

  const rewriteForm = useCallback((values: StoredForm) => {
    setStoredForm(values)
  }, [setStoredForm])

  const resetForm = () => {
    setStoredForm(initialForm)
  }

  return {isEditing, setEditing, storedForm, saveForm, rewriteForm, resetForm}
}

export default useStoredForm
