import type {FC, HTMLAttributes} from 'react'
import styled, {css} from 'styled-components'


type SmallProps = HTMLAttributes<HTMLElement>

type HelperTextProps = {
  state?: keyof typeof stateVariants
  inheritColor?: boolean
  noGap?: boolean
}

type StateVariantsProps = {
  $inheritColor: HelperTextProps['inheritColor']
}

type StyledTextProps = {
  $noGap: HelperTextProps['noGap']
  $inheritColor: HelperTextProps['inheritColor']
  $state: HelperTextProps['state']
}

export const stateVariants = {
  valid: css<StateVariantsProps>`
    color: ${({theme, $inheritColor}) => $inheritColor ? 'inherit' : theme.colors.textInverted};
  `,
  invalid: css`
    color: ${({theme}) => theme.colors.negative};
  `,
} as const

const StyledText = styled.small<StyledTextProps>`
  ${({theme, $noGap}) => !$noGap && css`
    padding-left: ${`calc(${theme.spacer} * 1.25) `};
  `}
  color: ${({theme, $inheritColor}) => $inheritColor ? 'inherit' : theme.colors.textInverted};

  ${({$state}) => $state && stateVariants[$state]}
`

const HelperText: FC<SmallProps & HelperTextProps> = ({state, inheritColor = false, noGap = false, ...props}) => (
  <StyledText $state={state} $inheritColor={inheritColor} $noGap={noGap} {...props} />
)

export default HelperText
