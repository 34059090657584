import type {FC, HTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import media from '../../utils/media'


type DivProps = HTMLAttributes<HTMLDivElement>

type SectionContainerProps = {
  bigMobilePadding?: boolean
  padding?: string
}

type StyledDivProps = {
  $padding: SectionContainerProps['padding']
  $bigMobilePadding: SectionContainerProps['bigMobilePadding']
}

const StyledDiv = styled.div<StyledDivProps>`
  padding: ${({$padding}) => $padding ? $padding : '3rem 0'};

  ${({$bigMobilePadding}) => $bigMobilePadding && css`
    ${media.down('mobile')(css`
      padding-top: 8rem;
    `)}
  `}
`

const SectionContainer: FC<DivProps & SectionContainerProps> = ({bigMobilePadding, padding, ...props}) => (
  <StyledDiv $padding={padding} $bigMobilePadding={bigMobilePadding} {...props} />
)

export default SectionContainer
