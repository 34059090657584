import {isEmpty, isUndefined} from 'lodash-es'
import t from '../translations'


export const validateUndefined = (value: unknown) => {
  if (isUndefined(value)) return t('form.errorRequired')
}

export const validateEmpty = (value: unknown) => {
  if (isEmpty(value)) return t('form.errorRequired')
}
