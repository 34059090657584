import type {ComponentProps, FC} from 'react'
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrowUp.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const ArrowUpIcon: FC<IconProps> = (props) => (
  <Icon icon={ArrowUp} {...props} />
)

export default ArrowUpIcon
