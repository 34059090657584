import type {ComponentProps, FC} from 'react'
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrowDown.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const ArrowDownIcon: FC<IconProps> = (props) => (
  <Icon icon={ArrowDown} {...props} />
)

export default ArrowDownIcon
