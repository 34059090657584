import {If} from 'babel-plugin-jsx-control-statements'
import {map, range, reduce} from 'lodash-es'
import {useFormState} from 'react-final-form'
import {Flex, HoverableTooltip, SectionContainer, Text} from 'uikit'
import type {DamageClaims as CalculationDamageClaims} from '../../../../../../common/schemas/calculations'
import {PRIVATE_PERSON} from '../../../../../../constants/legalFormTypes'
import {accidentsCompany, accidentsPrivate} from '../../../../constants/accidents'
import {damageClaimsCompany, damageClaimsPrivate} from '../../../../constants/damageClaims'
import t from '../../../../translations'
import {validateUndefined} from '../../../../utils/validate'
import AutocompleteInput from '../../../form/AutocompleteInput'
import BooleanInput from '../../../form/BooleanInput'
import type {GeneralForm} from '../utils'


export const parseDamageClaims = (values: Partial<GeneralForm>) => {
  const emptyClaims: Partial<CalculationDamageClaims> = {
    claims12: undefined,
    claims24: undefined,
    claims36: undefined,
    claims48: undefined,
    claims60: undefined,
    claims72: undefined,
    claims120: undefined,
  }

  if (values.didHavePZP && values.accidents && values.damageClaim) {
    const claims = reduce(values.damageClaim.slice(0, Number(values.accidents)), (result, key) => {
      result[key] = result[key] ? result[key] + 1 : 1
      return result
    }, emptyClaims)

    return {
      ...claims,
      claims120: values.legalForm === PRIVATE_PERSON ? claims.claims120 : undefined,
    }
  }

  return emptyClaims
}

const DamageClaims = () => {
  const {values} = useFormState<GeneralForm>({subscription: {values: true}})
  return (
    <Flex column>
      <BooleanInput
          name="didHavePZP"
          label={t('form.didHavePZP')}
          options={[t('form.yes'), t('form.no')]}
          required
      />
      <SectionContainer padding="1rem 0 0">
        <If condition={values.didHavePZP}>
          <SectionContainer padding="0 0 0.5rem">
            <Flex mainAxis="justify" crossAxis="center">
              <Text size={16} weight="bold">
                {values.legalForm === PRIVATE_PERSON ? t('form.accidentPrivate') : t('form.accidentCompany')}
              </Text>
              <HoverableTooltip variant="secondary" iconColor="text" /* eslint-disable-next-line react/no-danger */>
                <span dangerouslySetInnerHTML={{__html: t('form.accidentTooltip')}} />
              </HoverableTooltip>
            </Flex>
          </SectionContainer>
          <Flex column gap={false} width="hug">
            <AutocompleteInput
                name="accidents"
                label={t('form.numberOfAccidents')}
                options={
                  values.legalForm === PRIVATE_PERSON
                    ? map(accidentsPrivate, (accidentPrivate) => (
                      {...accidentPrivate, value: String(accidentPrivate.value)}
                    ))
                    : map(accidentsCompany, (accidentCompany) => (
                      {...accidentCompany, value: String(accidentCompany.value)}
                    ))
                }
                required
            />
            {range(1, Number(values.accidents || (values.damageClaim?.length ?? 0)) + 1).map((accident) => (
              <>
                <SectionContainer padding="0 0 0.5rem">
                  <Text size={16} weight="bold">{`${t('form.damageClaim')} ${accident}`}</Text>
                </SectionContainer>
                <Flex distribute mainAxis="justify">
                  <AutocompleteInput
                      name={`damageClaim[${accident - 1}]`}
                      label={t('form.damageClaimOccurred')}
                      options={values.legalForm === PRIVATE_PERSON ? damageClaimsPrivate : damageClaimsCompany}
                      fieldProps={{validate: validateUndefined}}
                      required
                  />
                </Flex>
              </>
            ))}
          </Flex>
        </If>
      </SectionContainer>
    </Flex>
  )
}

export default DamageClaims
