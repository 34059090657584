import invariant from 'invariant'
import {get} from 'lodash-es'
import accept from './accept.yml'
import allianzForm from './allianz/form.yml'
import csobEnums from './csob/enums.yml'
import csobForm from './csob/form.yml'
import damageClaims from './damageClaims.yml'
import enums from './enums.yml'
import form from './form.yml'
import general from './general.yml'
import generaliEnums from './generali/enums.yml'
import generaliForm from './generali/form.yml'
import genertelEnums from './genertel/enums.yml'
import genertelForm from './genertel/form.yml'
import insuranceCompany from './insuranceCompany.yml'
import komunalnaEnums from './komunalna/enums.yml'
import komunalnaForm from './komunalna/form.yml'
import kooperativaEnums from './kooperativa/enums.yml'
import kooperativaForm from './kooperativa/form.yml'
import nationalities from './nationalities.yml'
import notFound from './pages/404.yml'
import blog from './pages/blog.yml'
import contract from './pages/contract.yml'
import index from './pages/index.yml'
import insuranceCompanies from './pages/insuranceCompanies.yml'
import priceChange from './priceChange.yml'
import states from './states.yml'
import summary from './summary.yml'
import termination from './termination.yml'
import unionEnums from './union/enums.yml'
import unionForm from './union/form.yml'
import uniqaForm from './uniqa/form.yml'
import wustenrotEnums from './wustenrot/enums.yml'
import wustenrotForm from './wustenrot/form.yml'


export const createTemplate = (text: string, data: Record<string, string>) => {
  return text.replace(/{{([\s\S]+?)}}/g, (_match, value) => {
    return get(data, value, value)
  })
}

const translations = {
  accept,
  notFound,
  blog,
  index,
  contract,
  form,
  general,
  insuranceCompanies,
  insuranceCompany,
  summary,
  termination,
  enums,
  nationalities,
  priceChange,
  states,
  damageClaims,
  allianz: {
    form: allianzForm,
  },
  wustenrot: {
    enums: wustenrotEnums,
    form: wustenrotForm,
  },
  csob: {
    enums: csobEnums,
    form: csobForm,
  },
  komunalna: {
    enums: komunalnaEnums,
    form: komunalnaForm,
  },
  kooperativa: {
    enums: kooperativaEnums,
    form: kooperativaForm,
  },
  genertel: {
    enums: genertelEnums,
    form: genertelForm,
  },
  generali: {
    enums: generaliEnums,
    form: generaliForm,
  },
  union: {
    enums: unionEnums,
    form: unionForm,
  },
  uniqa: {
    form: uniqaForm,
  },
}

const t = (key: string):string => {
  const translation = get(translations, key, undefined)
  if (!translation) {
    invariant(translation, `Correct translation key is required: ${key}`)
    return ''
  }
  return translation
}

export default t
