import {If} from 'babel-plugin-jsx-control-statements'
import type {FC, LabelHTMLAttributes, ReactNode} from 'react'
import styled from 'styled-components'
import type {FieldTypes} from '../../constants/commonPropTypes'
import {px2rem} from '../../theme/theme'
import {generateTypography} from '../../theme/typography'
import {CircleCheckMarkIcon, CircleCrossMarkIcon} from '../icons'
import HelperText from '../typography/HelperText'


const FormComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  margin: ${px2rem(2)} 0;
  width: 100%;
`

const LabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.span`
  ${generateTypography({size: 12, withResponsive: false})}

  display: flex;
  align-items: center;
`

const StyledAsterisk = styled.sup`
  padding-left: 0.25rem;
  color: ${({theme}) => theme.colors.primary};
`

const MarkWrapper = styled.div`
  display: flex;
  padding-left: 0.25rem;
`

const StyledHelperText = styled(HelperText)`
  height: ${px2rem(20)};
`

type LabelProps = LabelHTMLAttributes<HTMLLabelElement>

type FormComponentProps = Omit<FieldTypes, 'name' | 'onChange'> & {
  children: ReactNode
}

const FormComponent: FC<LabelProps & FormComponentProps> = ({
  label, required, disabled, state, helperText, children, ...props
}) => {
  return (
    <FormComponentWrapper>
      <LabelWrapper>
        <StyledLabel {...props}>
          {label}
          <If condition={required && label}>
            <StyledAsterisk>*</StyledAsterisk>
          </If>
          <If condition={label}>
            <MarkWrapper>
              <If condition={!disabled && state === 'valid'}>
                <CircleCheckMarkIcon noFill size="xs" />
              </If>
              <If condition={!disabled && state === 'invalid'}>
                <CircleCrossMarkIcon noFill size="xs" />
              </If>
            </MarkWrapper>
          </If>
        </StyledLabel>
        <StyledContainer>
          {children}
        </StyledContainer>
      </LabelWrapper>
      <StyledHelperText state={state} inheritColor noGap>
        {helperText}
      </StyledHelperText>
    </FormComponentWrapper>
  )
}

export default FormComponent
