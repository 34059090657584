import type {ComponentProps, FC} from 'react'
import {ReactComponent as CircleCrossMark} from '../../../assets/icons/circleCrossMark.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const CircleCrossMarkIcon: FC<IconProps> = (props) => (
  <Icon icon={CircleCrossMark} {...props} />
)

export default CircleCrossMarkIcon
