import {useEffect, useState} from 'react'
import type {Breakpoint} from '../theme/theme'
import theme from '../theme/theme'


const useMediaTier = (media: Breakpoint) => {
  const [mediaMatches, setMediaMatches] = useState<boolean | null>(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(`(max-width: ${theme.responsive.breakpoints[media]}px)`)
      setMediaMatches(mediaQuery.matches)
      const handleMediaMatches = (mediaQuery: MediaQueryListEvent) => setMediaMatches(mediaQuery.matches)
      mediaQuery.addListener(handleMediaMatches)

      return () => mediaQuery.removeListener(handleMediaMatches)
    }
  }, [media])
  return {mediaMatches}
}
export default useMediaTier
