export const VEHICLE_TYPE_CAR_M1 = 'carM1' as const
export const VEHICLE_TYPE_CAR_N1 = 'carN1' as const
export const VEHICLE_TYPE_MOTORCYCLE = 'motorcycle' as const
export const VEHICLE_TYPE_TRAILER = 'trailer' as const
export const VEHICLE_TYPE_SCOOTER = 'scooter' as const
export const ALL_VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR_M1,
  VEHICLE_TYPE_CAR_N1,
  VEHICLE_TYPE_MOTORCYCLE,
  VEHICLE_TYPE_TRAILER,
  VEHICLE_TYPE_SCOOTER,
] as const

export type VehicleType = typeof ALL_VEHICLE_TYPES[number]

export const SUPPORTED_VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR_M1,
  VEHICLE_TYPE_CAR_N1,
  VEHICLE_TYPE_SCOOTER,
] as const

export type SupportedVehicleType = typeof SUPPORTED_VEHICLE_TYPES[number]

export const CAR_VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR_M1,
  VEHICLE_TYPE_CAR_N1,
] as const

export type VehicleTypeCar = typeof CAR_VEHICLE_TYPES[number]
