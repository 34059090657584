export const PHONE_NUMBER = 'phonenumber'
export const PHONE_CALL = 'phonecall'
export const BORN_NUMBER = 'bornnumber'
export const SCOOTER = 'scooter'
export const ACCIDENT_INSURANCE = 'accidentInsurance'

export const ALL_AB_TESTS = [
  PHONE_NUMBER,
  PHONE_CALL,
  BORN_NUMBER,
  SCOOTER,
  ACCIDENT_INSURANCE,
] as const

export type AbTest = typeof ALL_AB_TESTS[number]
