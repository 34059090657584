import type {FC, HTMLAttributes} from 'react'
import styled, {css, keyframes} from 'styled-components'
import type {Color} from '../theme/theme'


type DivProps = HTMLAttributes<HTMLDivElement>

type SkeletonProps = {
  width?: string
  height?: string
  color?: Color
}

type StyledSkeletonProps = {
  $width: SkeletonProps['width']
  $height: SkeletonProps['height']
  $color: SkeletonProps['color']
}

const pulse = keyframes`
  50% {
    opacity: 0.5;
  }
`

const StyledSkeleton = styled.div<StyledSkeletonProps>`
  border-radius: 0.5rem;
  ${({theme, $color}) => $color && css`
    background: ${theme.colors[$color]};
  `}
  ${({$width}) => $width && css`
    width: ${$width};
  `}
  ${({$height}) => $height && css`
    height: ${$height};
  `}
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`

const Skeleton: FC<DivProps & SkeletonProps> = ({width = '100%', height = '1.5rem', color = 'secondary', ...props}) => (
  <StyledSkeleton
      $width={width}
      $height={height}
      $color={color}
      {...props}
  />
)

export default Skeleton
